<template>
  <transition name="slide-fade">
    <div id="logs">
      <table>
        <thead>
        <tr>
          <th class="timeTd">{{ $t("logs.time") }}</th>
          <th class="nameTd">{{ $t("logs.user") }}</th>
          <th class="messageTh">{{ $t("logs.message") }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="log of this.historyLogs" :key="log.id">
          <td class="timeTd">{{ moment(log.date).format('DD.MM.YYYY HH:mm') }}</td>
          <td class="nameTd">{{ log.user }}</td>
          <td class="messageTd" v-html="log.message"></td>
        </tr>
        </tbody>
      </table>
      <h4 v-if="this.historyLogs.length === 0" style="width: 50%; text-align: center; margin: 0 auto; padding: 5px 15px 15px 15px; align-self: center">{{ $t("mainLog.logsNotFound") }}</h4>
    </div>
  </transition>
</template>

<script>
import moment from "moment";

export default {
  props: {
    historyLogs: {
      type: Array,
      required: true
    }
  },
  methods: {
    moment,
  }
}
</script>

<style scoped lang="scss">
@import "../../../public/styles/logsMenu";

table {
  width: 100%;
}
</style>