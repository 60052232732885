<template>
  <div class="container">
    <div class="title">
      <div class="ticket-name">
        <div id="back">
          <div @click="goBack" class="fa fa-angle-left"></div>
          <span class="name">{{ $t("singleTicket.ticket") }} #{{ ticket.ticketId }}</span>
        </div>
      </div>
      <div class="ticket-tools">
        <div class="fa fa-clone" v-if="isNotNewUserTicket()" @click="cloneTicket">
          <span class="clonehint">{{ $t("singleTicket.cloneHint") }}</span>
        </div>
        <div v-if="(isRoleAdmin || (isOwner && isTicketOpen()))" class="fa fa-trash-o" @click="deleteTicket">
          <span class="deletehint">{{ $t("singleTicket.deleteHint") }}</span>
        </div>
        <span class="line">|</span>
        <span id="label" class="data">{{ $t("singleTicket.lastUpdate") }} </span>
        <span id="format" class="data">{{ moment(ticket.updateDate).format('DD.MM.YYYY HH:mm') }}</span>
      </div>
    </div>

    <vue-confirm-dialog></vue-confirm-dialog>

    <div class="content">
      <form id="ticket-form" autocomplete="off">
        <div class="input-field col s12">
          <label class="active">{{ $t("singleTicket.ticketType") }}</label>
          <div ref="ticketType" class="ticketType" :key="localizationChangedKey">
            {{ ticketType != null ? $t("ticketType." + ticketType.name) : '' }}
          </div>
        </div>

        <div class="input-field col s12" v-if="isNotNewUserTicket()">
          <label for="manufacturer" class="active">{{ $t("singleTicket.manufacturer") }}</label>
          <input id="manufacturer" type="text"
                 v-model="content.manufacturer"
                 list="selectManufacturer"
                 class="changeable"
                 @input="getItemsByManufacturerName"
                 disabled>
          <datalist id="selectManufacturer"
                    class="changeable"
                    @change="getItemsByManufacturerName"
                    disabled>
            <option v-for="manufacturer in manufacturers" :key="manufacturer.id"
                    v-bind:value="manufacturer.name">
              {{ manufacturer.name }}
            </option>
          </datalist>
        </div>

        <div class="input-field col s12" v-if="isNotNewUserTicket()" >
          <label for="system" class="active">{{ $t("singleTicket.system") }}</label>
          <input id="system" type="text"
                 class="changeable"
                 v-model="content.system"
                 list="itemsByManufacturer"
                 @input="onSystemNameChanged"
                 disabled>
          <datalist id="itemsByManufacturer" class="changeable" @change="onSystemNameChanged" disabled>
            <option v-for="item in selectedManufacturerItems" :key="item.id" v-bind:value="item.system">
              {{ item.system }}
            </option>
          </datalist>
        </div>

        <div class="input-field col sl2"
             v-if="isNotNewUserTicket() && isNotDebugTicket()">
          <label for="mountType" class="active">{{ $t("singleTicket.mountType") }}</label>
          <input type="text"
                 id="mountType"
                 class="changeable"
                 v-model="content.mountType"
                 list="selectMountTypes"
                 disabled>
          <datalist id="selectMountTypes" class="changeable" disabled>
            <option v-for="mountType in mountTypes" :key="mountType" v-bind:value="mountType">
              {{ mountType }}
            </option>
          </datalist>
        </div>

        <div class="input-field col" v-if="isNotNewUserTicket() && isNotDebugTicket()">
          <label for="width" class="active">
            <span v-if="!this.isWidthValid" class="invalid_value">{{ $t("singleTicket.enterWidth") }}</span>
            <span v-else>{{ $t("singleTicket.width") }}</span>
          </label>
          <input id="width" type="text" class="changeable" disabled v-model.number="content.width">
        </div>

        <div class="input-field col" v-if="isNotNewUserTicket() && isNotDebugTicket()">
          <label for="depth" class="active">
            <span v-if="!this.isDepthValid" class="invalid_value">{{ $t("singleTicket.enterDepth") }}</span>
            <span v-else>{{ $t("singleTicket.depth") }}</span>
          </label>
          <input id="depth" type="text" class="changeable" disabled v-model.number="content.depth">
        </div>

        <div class="input-field col" v-if="isNotNewUserTicket() && isNotDebugTicket()">
          <label for="thickness" class="active">
            <span v-if="!this.isThicknessValid" class="invalid_value">{{ $t("singleTicket.enterThickness") }}</span>
            <span v-else>{{ $t("singleTicket.thickness") }}</span>
          </label>
          <input id="thickness" type="text" class="changeable" disabled v-model.number="content.thickness">
        </div>

        <div class="input-field col" v-if="isNotNewUserTicket() && isNotDebugTicket()">
          <label for="horizontalStep" class="active">
            <span v-if="!this.isL1Valid" class="invalid_value">{{ $t("singleTicket.enterHorizontalStep") }}</span>
            <span v-else>{{ $t("singleTicket.horizontalStep") }}</span>
          </label>
          <input id="horizontalStep" type="text" class="changeable" disabled v-model.number="content.horizontalStep">
        </div>

        <div class="input-field col" v-if="isNotNewUserTicket() && isNotDebugTicket()">
          <label for="verticalStep" class="active">
            <span v-if="!this.isL2Valid" class="invalid_value">{{ $t("singleTicket.enterVerticalStep") }}</span>
            <span v-else>{{ $t("singleTicket.verticalStep") }}</span>
          </label>
          <input id="verticalStep" type="text" class="changeable" disabled v-model.number="content.verticalStep">
        </div>

        <div class="input-field col" v-if="isNewUserTicket()">
          <label for="userId" class="active">{{ $t("singleTicket.userId") }}</label>
          <input id="userId" type="text" class="changeable" disabled v-model="content.id">
        </div>

        <div class="input-field col" v-if="isNewUserTicket()">
          <label for="username" class="active">{{ $t("singleTicket.username") }}</label>
          <input id="username" type="text" disabled v-model="content.username">
        </div>

        <div class="input-field col" v-if="isNewUserTicket()">
          <label for="email" class="active">{{ $t("singleTicket.email") }}</label>
          <input id="email" type="text" disabled v-model="content.email">
        </div>

        <div class="input-field col" v-if="isNewUserTicket()">
          <label for="firstname" class="active">{{ $t("singleTicket.firstName") }}</label>
          <input id="firstname" type="text" disabled v-model="content.firstName">
        </div>

        <div class="input-field col" v-if="isNewUserTicket()">
          <label for="lastname" class="active">{{ $t("singleTicket.lastName") }}</label>
          <input id="lastname" type="text" disabled v-model="content.lastName">
        </div>

        <div class="input-field col">
          <label for="notes" class="active">{{ $t("singleTicket.notes") }}</label>
          <textarea id="notes" type="text" class="changeable" disabled v-model="content.notes"/>
        </div>

        <div class="input-field col s12" >
          <label for="selectTicketStatus" class="active">{{ $t("singleTicket.ticketStatus") }}</label>
          <select id="selectTicketStatus" v-model="ticketStatus" class="admin-changeable" disabled>
            <option v-for="status in statuses" :key="status.id" v-bind:value="status">
              {{ $t("ticketStatus." + status.name) }}
            </option>
          </select>
        </div>

        <div class="input-field col s12">
          <label for="selectAssignedUser" class="active">{{ $t("singleTicket.assigned") }}</label>
          <select id="selectAssignedUser" class="changeable" v-model="assigned" disabled>
            <option v-for="admin in admins" :key="admin.id" v-bind:value="admin">
              {{ admin.username }}
            </option>
          </select>
        </div>
        <div id="controllers">
          <button type="button"
                  id="reject"
                  class="btn waves-effect waves-light"
                  v-if="isRoleAdmin && isTicketOpen() && !changeMode"
                  @click="askToRejectTicket" >{{$t("singleTicket.reject")}}<i class="material-icons right">delete</i>
          </button>

          <button type="button"
                  id="edit"
                  class="btn waves-effect waves-light"
                  v-if="(isRoleAdmin || (isOwner && isTicketOpen())) && isNotNewUserTicket() && !changeMode"
                  @click="editTicketFields">{{ $t("singleTicket.edit") }}<i class="material-icons right">edit</i>
          </button>

          <button type="button"
                  id="createItem"
                  class="btn waves-effect waves-light"
                  v-if="isRoleAdmin && isTicketOpen() && isNotNewUserTicket() && ticketType.name === 'NEW REGAL' && !changeMode"
                  @click="createItem">{{ $t("singleTicket.createItem") }}<i class="fa fa-plus right"></i>
          </button>

          <button type="button"
                  id="updateItem"
                  class="btn waves-effect waves-light"
                  v-if="isRoleAdmin && isTicketOpen() && isNotNewUserTicket() && ticketType.name === 'UPDATE' && !changeMode"
                  @click="updateItem">{{ $t("singleTicket.updateItem") }}<i class="fa fa-angle-right right"></i>
                  </button>

          <button type="button"
                  id="submit-edit"
                  class="btn waves-effect waves-light"
                  @click="submitRequest">{{ $t("singleTicket.submit") }}<i class="material-icons right">done</i>
          </button>

          <button type="button"
                  id="reset"
                  class="btn waves-effect waves-light"
                  @click="resetFields">{{ $t("singleTicket.reset") }}<i class="material-icons right">cancel</i>
          </button>

          <button type="button"
                  id="submitUser"
                  class="btn waves-effect waves-light"
                  v-if="isTicketOpen() && isNewUserTicket() && !changeMode"
                  @click="submitUser">{{ $t("singleTicket.submitUser") }}<i class="material-icons right">person</i></button>
        </div>
      </form>
    </div>

    <div class="image" v-if="initialized && isNotNewUserTicket()">
      <div class="image spin" v-if="upload">
        <RingLoader color="red"/>
      </div>
      <div v-else>
        <div v-if="photoQuantity === 0" @click="onSelectFile">
          <img src="../../../public/no-photo.jpg" v-bind:alt="$t('singleTicket.noImageAlt')">
        </div>
        <div v-else-if="photoQuantity > 0">
          <div style="display: flex;">
            <div class="fa fa-angle-left" style="float: left; font-size: 150%;" @click="nextImage(-1)"></div>
            <button class="download-photo-button btn waves-effect waves-light">
              <a id="downloadBtnText" :href="currentImage" download>
                {{ $t("singleTicket.downloadImage", [selectedPhotoNumber + 1, photoQuantity]) }}
              </a>
            </button>
            <div class="fa fa-angle-right" @click="nextImage(1)" style="float: right; font-size: 150%"></div>
          </div>

          <img v-show="!isVideo" :src="currentImage" v-bind:alt="$t('singleTicket.imageAlt')">
          <video v-show="isVideo" :src="currentImage" muted controls></video>
        </div>

        <input type="file" :accept="fileFormat" @change="onFileSelected" style="display: none" ref="fileInput">

        <div v-if="changeMode && photoQuantity > 0">
          <div id="delete-img" @click="onDeleteImage">
            <div id="deleteImg" class="fa fa-trash-o">
              <span v-if="isVideo" id="deleteImg"> {{ $t("singleTicket.deleteVideo") }}</span>
              <span v-else id="deleteImg"> {{ $t("singleTicket.deleteImage") }}</span>
            </div>
          </div>
        </div>

        <div v-if="changeMode && photoQuantity < maxImages">
          <div id="upload-img" @click="onSelectFile">
            <div id="fa-upload" class="fa fa-upload">
              <span v-if="isVideo" id="upNewImg"> {{ $t("singleTicket.uploadVideo") }}</span>
              <span v-else id="upNewImg"> {{ $t("singleTicket.uploadImage") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="initialized" class="logs">
      <LogMenu v-bind:historyLogs="historyLogs"/>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Link from "../utils/Link";
import ui from "../utils/ui";
import moment from "moment";
import $ from "jquery";
import Vue from "vue";
import VueToast from "vue-toast-notification";
import VueConfirmDialog from "vue-confirm-dialog";
import {RingLoader} from '@saeris/vue-spinners'
import LogMenu from "../historyLog/LogMenu";
import App from "../../App";
import ManufacturersBoard from "../manufacturer/ManufacturersBoard";
import {GlobalLocalizationEventEmitter} from "@/i18n";
import SideBarMenu from "@/components/SideBarMenu.vue";

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

export default {
  props: {
    id: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      maxImages: 5,
      photoQuantity: undefined,
      selectedPhotoNumber: 0,
      ticket: {},
      assigned: undefined,
      ticketTypes: [],
      ticketType: undefined,
      showItems: false,
      currentImage: '',
      admins: {},
      manufacturerName: "",
      statuses: [],
      ticketStatus: undefined,
      content: {},
      upload: true,
      initialized: false,
      historyLogs: [],
      isVideo: false,
      changeMode: false,
      fileFormat: 'image/*',
      userId: 0,
      user: {},
      loggedInUserId: -1,
      manufacturers: [],
      selectedManufacturerItems: [],
      mountTypes: [],
      currentManufacturer: undefined,
      currentItem: undefined,
      localizationChangedKey: 0,
      callback: undefined,
      isOwner: false,
      isRoleAdmin: false,
      isWidthValid: true,
      isDepthValid: true,
      isThicknessValid: true,
      isL2Valid: true,
      isL1Valid: true
    };
  },

  components: {
    RingLoader,
    LogMenu
  },

  created() {
    this.callback = GlobalLocalizationEventEmitter.$on("localizationChanged", () => {
      this.localizationChangedKey += 1;
    })
  },

  beforeDestroy() {
    GlobalLocalizationEventEmitter.$off("localizationChanged", this.callback);
  },

  mounted() {
    this.loggedInUserId = window.localStorage.getItem("id");
    this.isRoleAdmin = SideBarMenu.methods.isRoleAdmin();
    this.upload = true;
    this.initialization(this.$props.id).then( () => {
      this.upload = false;
      this.initialized = true;
    });
  },

  methods: {
    getLogs(ticketId) {
      const headers = Link.methods.getHeaders();
      return axios.get(
        Link.methods.getTicketLogs(ticketId),
       {headers}
      ).then(res => {
        this.historyLogs = res.data;
        for (let i = 0; i < this.historyLogs.length; i++) {
          this.historyLogs[i].message = App.methods.getLink(this.historyLogs[i].message);
        }
      }).catch(reason => {
        if (!reason.toString().includes("404")) {
          this.createToast(this.$t('mainLog.logsNotFound'), "success")
        }
      })
    },

    moment,

    initialization(ticketId) {
      return this.getAdminUsers().then( () => {
        this.getLogs(ticketId).then( () => {
          this.getManufacturers().then( () => {
            this.getMountTypes().then( () => {
              this.getTicketTypes().then(() => {
                this.getStatuses().then(() => {
                  this.getTicket(ticketId).then(() => {
                    this.fmtFields();
                    this.checkFields();
                  });
                });
              });
            });
          });
        });
      });
    },

    onDeleteImage() {
      if (
          this.photoQuantity > 0
          && this.selectedPhotoNumber >=0
          && this.selectedPhotoNumber < this.photoQuantity
      ) {
        this.ticket.attachmentList.splice(this.selectedPhotoNumber, 1);
        this.photoQuantity = this.ticket.attachmentList.length;
        if (this.selectedPhotoNumber > (this.photoQuantity - 1)) {
          let prevSelectedPhotoNumber = this.selectedPhotoNumber;
          this.selectedPhotoNumber = this.photoQuantity > 0 ? this.photoQuantity - 1 : 0;

          if (prevSelectedPhotoNumber !== this.selectedPhotoNumber) {
            if (this.selectedPhotoNumber > 0) {
              this.$router.replace({
                name: 'ticketById',
                params: {'id': this.id.toString()},
                query: {'selectedPhotoNumber': this.selectedPhotoNumber.toString()}
              });
            } else {
              this.$router.replace({
                name: 'ticketById',
                params: {'id': this.id.toString()}
              });
            }
          }
        }
        this.updateImage();
      }
    },

    fmtFields() {
      this.content.horizontalStep = ui.methods.fmt(this.content.horizontalStep);
      this.content.verticalStep = ui.methods.fmt(this.content.verticalStep);
      this.content.width = ui.methods.fmt(this.content.width);
      this.content.depth = ui.methods.fmt(this.content.depth);
      this.content.thickness = ui.methods.fmt(this.content.thickness);
    },

    checkFields() {
      this.isWidthValid = (
          !ui.methods.isEmptyNumber(this.content.width)
          || this.content.width == null
          || !isNaN(this.content.width)
      );
      this.isDepthValid = (
          !ui.methods.isEmptyNumber(this.content.depth)
          || this.content.depth == null
          || !isNaN(this.content.depth)
      );
      this.isThicknessValid = (
          !ui.methods.isEmptyNumber(this.content.thickness)
          || this.content.thickness == null
          || !isNaN(this.content.thickness)
      );
      this.isL1Valid = (
          !ui.methods.isEmptyNumber(this.content.horizontalStep)
          || this.content.horizontalStep == null
          || !isNaN(this.content.horizontalStep)
      );
      this.isL2Valid = (
          !ui.methods.isEmptyNumber(this.content.verticalStep)
          || this.content.verticalStep == null
          || !isNaN(this.content.verticalStep)
      );

      return this.isWidthValid
          && this.isL2Valid
          && this.isL1Valid
          && this.isDepthValid
          && this.isThicknessValid;
    },

    onSelectFile() {
      if (this.changeMode) {
        this.$refs.fileInput.value = null;
        this.$refs.fileInput.click();
      }
    },

    onFileSelected(event) {
      $('#upload').css('display', 'block');
      let selectedFile = event.target.files[0];
      const fd = new FormData();
      fd.append('file', selectedFile)

      const headers = Link.methods.getHeaders();
      if (selectedFile != null) {
        this.upload = true;
        return axios.post(
            Link.methods.getFilePhotoUploadUrl(),
            fd,
            {headers}
        ).then(res => {
          this.ticket.attachmentList.push(res.data);
          this.photoQuantity = this.ticket.attachmentList.length;
          this.selectedPhotoNumber = this.photoQuantity - 1;
          this.updateImage();
          this.upload = false;
        }).catch((e) => {
          this.createToast(this.$t("singleTicket.imageNotLoaded", [e.message]), "error")
          this.upload = false;
        });
      }
    },

    getTicketTypes() {
      let headers = Link.methods.getHeaders();
      return axios.get(
        Link.methods.getTicketTypesUrl(),
        {headers}
      ).then(res => {
        this.ticketTypes = res.data;
      }).catch(() => {
        this.createToast(this.$t("singleTicket.ticketTypesNotFound"), "error")
      })
    },

    submitUser() {
      const userId = this.content.id;
      const username = this.content.username;
      const headers = Link.methods.getHeaders();
      return axios.post(
        Link.methods.getUserActivateLink(this.ticket.ticketId),
        {},
        {headers}
      ).then(() => {
        this.createToast(this.$t("singleTicket.userSubmitted", [userId, username]), "success");
        this.goBack()
      }).catch(() => {
        this.createToast(this.$t("singleTicket.unableToSubmitUser", [userId, username]), "error");
      })
    },

    goBack() {
      return this.$router.back();
    },

    resetFields() {
      if (this.changeMode) {
        this.changeMode = false;
        $('.changeable').prop('disabled', true);
        $('.admin-changeable').prop('disabled', true);
        $('#edit').css('display', 'block');
        $('#reset').css('display', 'none');
        $('#createItem').css('display', 'block');
        $('#submit-edit').css('display', 'none');
        $('#updateItem').css('display', 'block');
        this.initialization(this.id);
      }
    },

    isNewUserTicket() {
      return this.ticketType != null
         && this.ticketType.name === 'NEW USER';
    },

    isNotNewUserTicket() {
      return this.ticketType != null
          && this.ticketType.name !== 'NEW USER';
    },

    isDebugTicket() {
      return this.ticketType != null
          && this.ticketType.name === 'DEBUG';
    },

    isNotDebugTicket() {
      return this.ticketType != null
          && this.ticketType.name !== 'DEBUG';
    },

    isTicketOpen() {
      return this.ticketStatus != null
          && this.ticketStatus.name !== 'CLOSED'
          && this.ticketStatus.name !== 'REJECTED';
    },

    deleteTicket() {
      const ticketId = this.id;
      this.$confirm(
        {
          title: this.$t("singleTicket.deleteTitle"),
          message: this.$t("singleTicket.deleteMessage", [ticketId]),
          button: {
            no: this.$t("singleTicket.confirmNo"),
            yes: this.$t("singleTicket.confirmYes")
          },
          callback: confirm => {
            if (confirm) {
              const headers = Link.methods.getHeaders();
              axios.delete(
                Link.methods.getDeleteTicketsUrl(ticketId),
                {headers}
              ).then(() => {
                this.createToast(this.$t('singleTicket.ticketDeleted'), "success")
                this.goBack();
              }).catch(() => {
                this.createToast(this.$t('singleTicket.ticketDeletedError'), "error")
              });
            }
          }
        }
      )
    },

    updateItem() {
      if (this.currentItem == null) {
        return;
      }

      const imageUrl = (
        this.photoQuantity > 0
        && this.selectedPhotoNumber >= 0
        && this.selectedPhotoNumber < this.photoQuantity
      ) ? this.ticket.attachmentList[this.selectedPhotoNumber]
        : undefined;

      const ticketData = {
        "mountType":  this.content.mountType,
        "width": this.content.width,
        "depth": this.content.depth,
        "thickness": this.content.thickness,
        "horizontalStep": this.content.horizontalStep,
        "verticalStep": this.content.verticalStep,
        "imageUrl": imageUrl,
        "ticketId": this.id,
        "itemId": this.currentItem.id.toString()
      };
      const savedData = JSON.stringify(ticketData);
      window.localStorage.setItem("ticketData", savedData);
      this.$router.push({name: 'itemById', params: {"id": this.currentItem.id.toString()}});
    },

    cloneTicket() {
      if (!this.checkFields()) {
        return;
      }

      this.fmtFields();
      this.ticket.content = this.contentToString();
      this.ticket.ticketType = this.ticketType.name;
      this.ticket.ticketStatus = this.ticketStatus.name;
      if (this.assigned != null) {
        this.ticket.assigned = this.assigned.username;
        this.ticket.assignedId = this.assigned.id;
      }

      let headers = Link.methods.getHeaders();
      axios.post(
        Link.methods.getTicketCreateUrl(),
        this.ticket,
        {headers}
      ).then(response => {
        this.createToast(this.$t('singleTicket.cloned'), "success");
        this.ticket.ticketId = response.data.ticketId;
        this.$router.replace({
          name: 'ticketById',
          params: {"id": this.ticket.ticketId.toString()}
        });
        setTimeout(() => {
          this.selectedPhotoNumber = 0;
          this.updateImage();
          this.getLogs(this.ticket.ticketId);
          this.createToast(this.$t('singleTicket.redirected'), "success");
          this.editTicketFields()
        })
      }).catch(() => {
        this.createToast(this.$t('singleTicket.cloneNotCreated'), "error");
      });
    },

    rejectNewUserTicket() {
      const headers = Link.methods.getHeaders();
      const userId = this.content.id;
      const username = this.content.username;
      axios.put(
        Link.methods.getTicketStatusChangeUrl(this.id, 'REJECTED'),
        {},
        {headers}
      ).then(() => {
        axios.get(
          Link.methods.getUserByIdUrl(userId),
          {headers}
        ).then((res) => {
          if (res.data.role === 'GUEST') {
            axios.delete(
              Link.methods.getUserDeleteUrl(userId),
              {headers}
            ).then(() => {
              this.goBack();
              this.createToast(this.$t("singleTicket.userNotSubmitted", [userId, username]), "success");
            }).catch((e) => {
              console.error(e);
              this.goBack();
              this.createToast(this.$t("singleTicket.unableToDeleteRejectedUser", [userId, username]), "error");
            })
          }
        }).catch((e) => {
          console.error(e);
          this.createToast(this.$t("singleTicket.unableToDeleteRejectedUser", [userId, username]), "error");
        })
      }).catch((e) => {
        console.error(e);
        this.createToast(this.$t("singleItem.unableToCloseTicket", [this.id]), "error");
      })
    },

    rejectTicket() {
      const headers = Link.methods.getHeaders();
      axios.put(
        Link.methods.getTicketStatusChangeUrl(this.id, 'REJECTED'),
        {},
        {headers}
      ).then(() => {
        this.goBack();
        this.createToast(this.$t("singleTicket.ticketRejected", [this.id]), "success");
      }).catch((e) => {
        console.error(e);
        this.createToast(this.$t("singleItem.unableToCloseTicket", [this.id]), "error");
      })
    },

    askToRejectTicket() {
      if (this.isNewUserTicket()) {
        const userId = this.content.id;
        const username = this.content.username;

        this.$confirm(
          {
            title: this.$t("singleTicket.rejectNewUserTitle"),
            message: this.$t("singleTicket.rejectNewUserMessage", [userId, username]),
            button: {
              no: this.$t("singleTicket.confirmNo"),
              yes: this.$t("singleTicket.confirmYes")
            },
            callback: confirm => {
              if (confirm) {
                this.rejectNewUserTicket();
              }
            }
          }
        )
      } else {
        this.rejectTicket();
      }
    },

    editTicketFields() {
      this.changeMode = true;
      $('.changeable').prop('disabled', false);
      if (this.isRoleAdmin) {
        $('.admin-changeable').prop('disabled', false);
      }
      $('#edit').css('display', 'none');
      $('#createItem').css('display', 'none');
      $('#submit-edit').css('display', 'block');
      $('#reset').css('display', 'block')
      $('.image').css('pointer-events', 'auto');
      $('#updateItem').css('display', 'none');
    },

    submitRequest() {
      if (!this.checkFields()) {
         return;
      }

      this.fmtFields();
      this.changeMode = false;
      this.sendTicketEditRequest(this.id).then(() => {
        this.createToast(this.$t('singleTicket.edited', [this.ticket.ticketId]), "success")
      });
      $('.changeable').prop('disabled', true);
      if (this.isRoleAdmin) {
        $('.admin-changeable').prop('disabled', true);
      }
      $('#edit').css('display', 'block');
      $('#reset').css('display', 'none');
      $('#createItem').css('display', 'block');
      $('#submit-edit').css('display', 'none');
      $('#updateItem').css('display', 'block');
    },

    getTicket(ticketId) {
      const headers = Link.methods.getHeaders();
      return axios.get(
        Link.methods.getTicketByIdUrl(ticketId),
        {headers}
      ).then(res => {
        let ticket = res.data;
        this.isOwner = (ticket.userId.toString() === this.loggedInUserId);
        this.assigned = this.admins.find((it) => it.username === ticket.assigned);
        if (this.assigned == null && !ui.methods.isEmptyString(ticket.assigned) ) {
          this.assigned = {
            id: ticket.assignedId != null ? ticket.assignedId : -1,
            username: ticket.assigned
          }
          this.admins.push(this.assigned);
        }
        let current_status_name = (ticket.ticketStatus) ? ticket.ticketStatus : "NEW";
        const ticketStatus = this.statuses.find((el) => el.name === current_status_name);
        this.ticketStatus = ticketStatus ? ticketStatus : this.statuses[0];

        this.content = JSON.parse(ticket.content);
        for (let field in this.content) {
          if (this.content[field] === -1) {
            this.content[field] = '?';
          }
        }

        if (ticket.attachmentList == null) {
          if (ticket.attachments != null) {
            ticket.attachmentList = ticket.attachments
                .split(";")
                .filter( (s) => typeof s === 'string')
                .map( (s) =>  s.trim())
                .filter( (s) => s.length > 0)
            ticket.attachments = undefined;
          } else {
            ticket.attachmentList = [];
          }
        } else {
          ticket.attachments = undefined;
        }
        this.photoQuantity = ticket.attachmentList.length;
        this.selectedPhotoNumber = (
          this.$route.query.selectedPhotoNumber != null
        ) ? +this.$route.query.selectedPhotoNumber
          : 0;

        const ticketType = this.ticketTypes.find( el => el.name === ticket.ticketType);
        this.ticketType = ticketType != null ? ticketType : this.ticketTypes[0];

        this.ticket = ticket;
        if (this.isDebugTicket()) {
          this.maxImages = 1;
          this.fileFormat = 'video/*';
          this.isVideo = true;
        }
        this.updateImage();

        if (
            this.isNotNewUserTicket() &&
            this.content.id != null &&
            typeof this.content.id === 'number' &&
            this.content.id >= 0
        ) {
          // get item and manufacturer from this item's id
          const headers = Link.methods.getHeaders();
          return axios.get(
            Link.methods.getItemById(this.content.id),
            {headers}
          ).then((res) => {
            this.currentItem = res.data;
            this.currentManufacturer = this.currentItem.manufacturer;
            this.content.system = this.currentItem.system;
            this.content.manufacturer = this.currentManufacturer.name;
            this.getItemsByManufacturerName();
          }).catch(() => {
            this.getItemsByManufacturerName();
          });
        } else {
          this.getItemsByManufacturerName();
        }
      }).catch((e) => {
        console.error(e);
        this.createToast(this.$t('singleTicket.cantFindTicket'), "success");
      });
    },

    createItem() {
      const imageUrl = (
        this.photoQuantity > 0
        && this.selectedPhotoNumber >= 0
        && this.selectedPhotoNumber < this.photoQuantity
      ) ? this.ticket.attachmentList[this.selectedPhotoNumber]
        : undefined;

      const item = {
        "manufacturer": this.content.manufacturer,
        "system": this.content.system,
        "mountType":  this.content.mountType,
        "width": this.content.width,
        "depth": this.content.depth,
        "thickness": this.content.thickness,
        "horizontalStep": this.content.horizontalStep,
        "verticalStep": this.content.verticalStep,
        "imageUrl": imageUrl,
        "ticketId": this.id
      };
      const savedData = JSON.stringify(item);
      window.localStorage.setItem("ticketData", savedData);
      return this.$router.push({name: 'newItem'});
    },

    createToast(msg, type) {
      Vue.use(VueToast);
      Vue.$toast.open({
        type: type,
        message: msg,
        position: "bottom-right",
        duration: 5000
      });
    },

    sendTicketEditRequest(id) {
      let headers = Link.methods.getHeaders();
      if (this.currentItem != null) {
        this.content.id = this.currentItem.id;
      } else {
        this.content.id = undefined;
      }
      this.ticket.content = this.contentToString();
      this.ticket.ticketStatus = this.ticketStatus.name;
      this.ticket.ticketType = this.ticketType.name;
      this.ticket.assigned = this.assigned != null ? this.assigned.username : undefined;
      this.ticket.assignedId = this.assigned != null ? this.assigned.id : undefined;

      return axios.put(
        Link.methods.getTicketUpdateUrl(id),
        this.ticket,
        {headers}
      ).then(() => {
        this.getLogs(id);
      }).catch((e) => {
        console.error(e);
        return axios.put(
          Link.methods.getTicketUpdateUrl(id),
          this.ticket,
          {headers}
        ).then(() => {
          this.getLogs(id);
        });
      });
    },

    getAdminUsers() {
      let headers = Link.methods.getHeaders();
      return axios.get(
        Link.methods.getAllUsersUrl(),
        {headers}
      ).then(res => {
        if (res != null && res.data != null) {
          this.admins = res.data.filter((it) => it.role === 'ADMIN');
        }
      }).catch(() => {
         this.createToast(this.$t('singleTicket.failWithLoadUsers'), "error")
      });
    },

    getStatuses() {
      let headers = Link.methods.getHeaders();
      return axios.get(
        Link.methods.getStatusesUrl(),
        {headers}
      ).then(res => {
        this.statuses = res.data.filter((el) => el.name !== "NONE");
      }).catch((e) => {
        console.error(e);
        this.createToast(this.$t('singleTicket.failWithLoadStatuses'), "error");
      });
    },

    getManufacturers() {
      return ManufacturersBoard.methods.getManufacturers().then(data => {
        this.manufacturers = data;
      });
    },

    contentToString() {
      return JSON.stringify(this.content);
    },

    nextImage(num) {
      if (this.photoQuantity > 1) {
        this.selectedPhotoNumber += num;
        this.updateImage();

        if (this.selectedPhotoNumber !== 0) {
          this.$router.replace({
            name: 'ticketById',
            params: {'id': this.id.toString()},
            query: {'selectedPhotoNumber': this.selectedPhotoNumber}
          });
        } else {
          this.$router.replace({
            name: 'ticketById',
            params: {'id': this.id.toString()}
          });
        }
      }
    },

    updateImage() {
      if (this.photoQuantity === 0) {
        this.currentImage = undefined;
        this.selectedPhotoNumber = 0;
        return;
      }

      if (this.selectedPhotoNumber >= this.photoQuantity) {
        this.selectedPhotoNumber = 0;
      } else if (this.selectedPhotoNumber < 0) {
        this.selectedPhotoNumber = this.photoQuantity - 1;
      }

      this.currentImage = this.ticket.attachmentList[this.selectedPhotoNumber];

      $("#downloadBtnText").text(
        this.$t(
            "singleTicket.downloadImage",
            [
              this.selectedPhotoNumber + 1,
              this.ticket.attachmentList.length
            ]
        )
      )
    },

    getItemsByManufacturerName() {
      const mName = (this.content.manufacturer ? this.content.manufacturer.toUpperCase() : undefined);
      this.currentManufacturer = this.manufacturers.find( (el) =>
          (el.name ? el.name.toUpperCase() : '') === mName
      );
      if (this.currentManufacturer != null) {
        this.content.manufacturer = this.currentManufacturer.name;
        const manufacturerId = this.currentManufacturer.id;
        const headers = Link.methods.getHeaders();
        axios.get(Link.methods.getItemsByManufacturer(
          manufacturerId),
          {headers}
        ).then(res => {
          this.selectedManufacturerItems = res.data.filter( (it) =>
              it.status !== 'DELETED'
          );
          this.onSystemNameChanged();
        }).catch(reason => {
          console.error(reason)
        })
      } else {
        this.selectedManufacturerItems = [];
        this.onSystemNameChanged();
      }
    },

    onSystemNameChanged() {
      const systemName = (this.content.system ? this.content.system.toUpperCase() : undefined);
      if (this.currentManufacturer != null && systemName != null) {
        this.currentItem = this.selectedManufacturerItems.find((it) =>
          (it.system ? it.system.toUpperCase() : '') === systemName
        );
        if (this.currentItem) {
          this.content.system = this.currentItem.system;
        }
      } else {
        this.currentItem = undefined;
      }

      const ticketName = this.ticketType.name;
      if ((ticketName === 'UPDATE' || ticketName === 'NEW REGAL')) {
        const newTicketName = (this.currentItem != null ? 'UPDATE' : 'NEW REGAL');
        this.ticketType = this.ticketTypes.find((el) => el.name === newTicketName);
        this.$refs.ticketType.innerText = this.$t("ticketType." + this.ticketType.name);
      }
    },

    getMountTypes() {
      const headers = Link.methods.getHeaders();
      return axios.get(
        Link.methods.getMountTypes(), {headers}
      ).then(res => {
        this.mountTypes = res.data;
      })
    },
  }
}
</script>

<style scoped lang="scss">
label.active {
  margin-top: -2%;
}

input {
  margin-bottom: 10px;
}

#delete-img, #upload-img {
  display: flex;
  text-align: center;
}

#upload-img:hover, #delete-img:hover {
  cursor: pointer;

  #upNewImg {
    color: red;
  }

  #fa-upload {
    color: red;
  }
}

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import '../../../public/materialize-src/sass/materialize.scss';
@import '../../../public/styles/singleTicket.scss';
@import '../../../public/styles/hints.scss';

i.right {
  margin-left: 6px;
}

.btn {
  font-size: 13px;
  line-height: initial;
  padding: 0 4px 0 6px;
}

.input-field > label > span.invalid_value {
  color: red;
  font-size: 13px;
}

textarea#notes {
  height:auto;
  width: 100%;
  margin:0 0 8px 0;
  padding: 0 0 0 0.75rem;
  resize: none;
  box-sizing: border-box;
  color:black;
  border:none;
  font-family: BloggerSans,sans-serif !important;
}

</style>
